<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("riskZoneAssessments") }}</h1>
      <br>
      <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
      </div>
      </div>

        <div v-if="activePrompt1"  class="clearfix">

        <div class="vx-row">
        <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("mission") }}<b style="color: #ff6141">*</b></p>
        <vs-input
        disabled
        name="mission_name"
        v-model="mission_name"
        class="w-full"
        />
        </div>
        <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">

        <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
        <vs-select
        :closeOnSelect="false"
        v-model="site"
        data-vv-validate-on="blur"
        autocomplete
        name="site"
        class="w-full"
        >
        <vs-select-item :key="item1" :value="item1" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
        <span class="text-danger text-sm" v-show="errors.has('site')">{{ $t("site") }}</span>

        </div>

        <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
        <p>{{ $t("purpose") }}<b style="color: #ff6141">*</b></p>
        <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="name"
        v-model="name"
        class="w-full"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("name") }}</span>
        </div>


        </div>

        <vx-card class="mt-10" no-shadow card-border>
        <div class="vx-row ">
        <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">{{ $t("questionnaire") }} </span>
        </div>
        <vs-divider />
        </div>
        </div>

        <div class="vx-row ">

        <vs-table stripe  class="w-full  "  :data="questions_list">
        <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>

        </template>

        <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >

        <vs-td>{{indextr+1}}</vs-td>
        <vs-td >{{tr.description}}
        </vs-td>

        <vs-td >
          <vs-checkbox  v-model="tr.conditionVrai " @change="onOptionChange_Vrai(tr,indextr)">{{ $t("oui") }}</vs-checkbox>
        </vs-td>
        <vs-td >
          <vs-checkbox  v-model="tr.conditionFaux " @change="onOptionChange_Faux(tr,indextr)">{{ $t("non") }}</vs-checkbox>
        </vs-td>
        <vs-td >
          <vs-checkbox v-model="tr.conditionNa " @change="onOptionChange_Na(tr,indextr)">NA</vs-checkbox>
        </vs-td>
        </vs-tr>
        </template>
        </vs-table>
        <div>
          <vs-button color="primary"  class="mt-4" @click="submit_produit()" >{{ $t("Ajouter") }}</vs-button>
        </div>
        </div>
        </vx-card>

        <vx-card  class="mt-10" no-shadow card-border>
        <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{ $t("site_evalue") }}</span>
          </div>
          <vs-divider />
        </div>
        </div>
        <div class="vx-row ">
        </div>
        <div class="vx-row ">
        </div>
          <div class="vx-row ">
              <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_sites">
              <template slot="header">

              </template>
              <template slot="thead">
              <vs-th hidden  >N°</vs-th>
              <vs-th>Site(s)</vs-th>
              <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

                <vs-td hidden :data="data[indextr].id">
                  {{data[indextr].id}}
                </vs-td>
                <vs-td :data="data[indextr].site_obj">
                  {{data[indextr].site_obj.name}}
                </vs-td>           
                <vs-td class="whitespace-no-wrap">
                  <div class="flex">
<!--                     <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
 -->                    
                    <vs-button @click.stop="deleteProduit(data[indextr].site)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
                
                  </div>
                </vs-td>
              </vs-tr>
              </template>
              </vs-table>
          </div>
        </vx-card>

        <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Valider") }}</vs-button>

        </div>
      <!-- modification -->
        <div  v-if="activePrompt2"  class="clearfix">
        
          <div class="vx-row">
            
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
            <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
            <vs-input
            disabled
            name="mission_name"
            v-model="mission_name"
            class="w-full"
            />
            </div>

            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
             <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  :closeOnSelect="false"
                  v-model="site"
                  data-vv-validate-on="blur"
                  autocomplete
                  name="site"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
            <span class="text-danger text-sm" ><b>{{ $t("select_update") }}</b></span>
            
            </div>
    
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
            <p>{{ $t("purpose") }}<b style="color: #ff6141"></b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="name"
              v-model="name"
              class="w-full"
              />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("name") }}</span>
            </div>


          </div>

        <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none"> {{ $t("questionnaire") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
  
    
    <div class="vx-row ">
      <vs-table stripe class="w-full  " :data="produits_computed">
      <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th></vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
       
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
        
         
          <vs-td>{{indextr+1}}</vs-td>
          <!-- <vs-td>
            <vs-chip  v-if="tr.conditionVrai === true" :percent="100" :color="getOrderStatusColor1(tr.colorVrai)"></vs-chip>
            <vs-chip  v-if="tr.conditionFaux === true" :percent="100" :color="getOrderStatusColor1(tr.colorFaux)"></vs-chip>
            <vs-chip  v-if="tr.conditionNa === true" :percent="100" :color="getOrderStatusColor1(tr.colorNa)" ></vs-chip>

            <vs-progress v-if="tr.conditionVrai === true" :percent="100" :color="getOrderStatusColor1(tr.colorVrai)"></vs-progress>
            <vs-progress v-if="tr.conditionFaux === true" :percent="100" :color="getOrderStatusColor1(tr.colorFaux)"></vs-progress>
            <vs-progress v-if="tr.conditionNa === true" :percent="100" :color="getOrderStatusColor1(tr.colorNa)"></vs-progress>
 
          </vs-td> -->

          <vs-td >{{tr.description}}
          </vs-td>
          
          <vs-td >
            <vs-checkbox  v-model="tr.conditionVrai " @change="onOptionChange_Vrai_up(tr,indextr)">{{ $t("oui") }}</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox  v-model="tr.conditionFaux " @change="onOptionChange_Faux_up(tr,indextr)">{{ $t("non") }}</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox  v-model="tr.conditionNa " @change="onOptionChange_Na_up(tr,indextr)">NA</vs-checkbox>
         </vs-td>
        </vs-tr>
      </template>
      </vs-table>
      <div>
          <vs-button color="primary"  class="mt-4" @click="updateProduit()" >{{ $t("Ajouter") }}</vs-button>
      </div>
    </div>
 
          </vx-card>
          <vx-card  class="mt-10" no-shadow card-border>
        <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{ $t("site_evalue") }}</span>
          </div>
          <vs-divider />
        </div>
        </div>
        <div class="vx-row ">
        </div>
        <div class="vx-row ">
        </div>
          <div class="vx-row ">
              <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_sites">
              <template slot="header">

              </template>
              <template slot="thead">
              <vs-th hidden  >N°</vs-th>
              <vs-th>Site(s)</vs-th>
              <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

                <vs-td hidden :data="data[indextr].id">
                  {{data[indextr].id}}
                </vs-td>
                <vs-td :data="data[indextr].site_obj">
                  {{data[indextr].site_obj.name}}
                </vs-td>           
                <vs-td class="whitespace-no-wrap">
                  <div class="flex">
<!--                     <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
 -->                    
                    <vs-button @click.stop="deleteProduit(data[indextr].site)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
                
                  </div>
                </vs-td>
              </vs-tr>
              </template>
              </vs-table>
          </div>
        </vx-card>
          <vs-button color="primary"  class="float-right mt-4" @click="submitData()" >{{ $t("Valider") }}</vs-button>
        </div>
 
        <div v-if="activePrompt3"  class="clearfix">
          <div class="vx-row">
            
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
            <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
            <vs-input
            disabled
            name="mission_name"
            v-model="mission_name"
            class="w-full"
            />
            </div>

            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
             <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  :closeOnSelect="false"
                  v-model="site"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="site"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
            <span class="text-danger text-sm" ><b>{{ $t("select_show") }}</b></span>
            
            </div>
    
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
            <p>{{ $t("purpose") }}<b style="color: #ff6141"></b></p>
              <vs-input
                disabled
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="name"
                v-model="name"
                class="w-full"
              />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("name") }}</span>
            </div>


          </div>

        <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{ $t("questionnaire") }} </span>
          </div>
          <vs-divider />
        </div>
      </div>
  
    
    <div class="vx-row ">
      <vs-table stripe class="w-full  " :data="produits_computed">
      <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th></vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
       
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
        
         
          <vs-td>{{indextr+1}}</vs-td>
          <vs-td>
            <vs-chip  v-if="tr.conditionVrai === true" :percent="100" :color="getOrderStatusColor1(tr.colorVrai)"></vs-chip>
            <vs-chip  v-if="tr.conditionFaux === true" :percent="100" :color="getOrderStatusColor1(tr.colorFaux)"></vs-chip>
            <vs-chip  v-if="tr.conditionNa === true" :percent="100" :color="getOrderStatusColor1(tr.colorNa)" ></vs-chip>

            <!-- <vs-progress v-if="tr.conditionVrai === true" :percent="100" :color="getOrderStatusColor1(tr.colorVrai)"></vs-progress>
            <vs-progress v-if="tr.conditionFaux === true" :percent="100" :color="getOrderStatusColor1(tr.colorFaux)"></vs-progress>
            <vs-progress v-if="tr.conditionNa === true" :percent="100" :color="getOrderStatusColor1(tr.colorNa)"></vs-progress>
 -->
          </vs-td>

          <vs-td >{{tr.description}}
          </vs-td>
          
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionVrai " @change="onOptionChange_Vrai_up(tr,indextr)">{{ $t("oui") }}</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionFaux " @change="onOptionChange_Faux_up(tr,indextr)">{{ $t("non") }}</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionNa " @change="onOptionChange_Na_up(tr,indextr)">NA</vs-checkbox>
         </vs-td>
        </vs-tr>
      </template>
      </vs-table>
    </div>
 
          </vx-card>
         
          <vs-button color="primary"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
        </div>
    </div>
  </template>
<script>

import { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'Le champ libelle  est obligatoire'
    },
    site: {
      required: 'Le champ site est obligatoire'
    }
   
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      action_button:'',
      missionControle_data:'',
      question_list_update:[],
      produits_sites:[],
      mission_name:'',
      mission_id: '',
      selectedOption: null,
      sites_list:[],
      questions_list:[],
      sites_tab:'',
      conditions_tab:false,
      
      questions_tab:'',
      // conditions_Questions:[],

      id_produit:1,
      produits_computed:[],
      produits_computed_new_finale:[],
      produit_submit:[],
      id:'',
      id_up:'',
      old_produitID:[],
      repProduit:[],
      repProduit_new:[],
      itemIndexProduit:-1,
      update_produitChek : false,
      Has_Med_Dos_Qty_update : false,

      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,

      site:'',
      riskZoneAssessments: [],
    
      selected: [],
      selected_up:[],
      isMounted: false,
      itemsPerPage: 20,

      ApprouveObject: {
        true: 'Oui',
        false: 'Non'
      },
      show_update : false
    }
  },
  
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.riskZoneAssessments.length
    },
    validate () {
      if (
        (
          this.questions_tab !== ''
        )) return true
    }
  },
  watch:{
    site (value) {
      if (value) {
        for (let i = 0; i < this.produits_computed_new_finale.length; i++) {
          if (this.produits_computed_new_finale[i].site === value.id) {
            this.produits_computed = []
            this.produits_computed = this.produits_computed_new_finale[i].question_finale
          }
        }
      }
    }
  },
  methods: {
    
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    onOptionChange_Vrai (tr) {
      this.obj_ques = ''
      if (tr.conditionVrai === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: true,
          conditionFaux: false,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },
    onOptionChange_Faux (tr) {
      this.obj_ques = ''
      if (tr.conditionFaux === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: true,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },
    onOptionChange_Na (tr) {
      this.obj_ques = ''
      if (tr.conditionNa === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: false,
          conditionNa: true

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },

    onOptionChange_Vrai_up (tr) {
      this.obj_ques = ''
      if (tr.conditionVrai === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: true,
          conditionFaux: false,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    onOptionChange_Faux_up (tr) {
      this.obj_ques = ''
      if (tr.conditionFaux === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: true,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    onOptionChange_Na_up (tr) {
      this.obj_ques = ''
      if (tr.conditionNa === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: false,
          conditionNa: true

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    handleSelected_up (tr) {
      this.obj_ques = ''
      if (tr.condition === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          condition: false,
          question:tr.question,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai
        }
        this.UPDATE_ITEM_PRODUIT_2(this.produits_computed, obj_ques)

      } else if (tr.condition === false) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          condition: true,
          question:tr.question,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai
          

        }
        this.UPDATE_ITEM_PRODUIT_2(this.produits_computed, obj_ques)
      }

      /*   
      console.log('voir l\'objet ', tr)
      this.$vs.notify({
        title: `Selected ${tr.description}`,
        text: `Email: ${tr.condition}`
      }) */
    },
    handleSelected (tr) {
      this.obj_ques = ''
      if (tr.condition === true) {
        const obj_ques = {
          id: tr.id,
          category:tr.category,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai,
          condition: false,
          description:tr.description,
          created_at:tr.created_at,
          updated_at:tr.updated_at

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } else if (tr.condition === false) {
        const obj_ques = {
          id: tr.id,
          category:tr.category,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai,
          condition: true,
          description:tr.description,
          created_at:tr.created_at,
          updated_at:tr.updated_at

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)
      }

      /*   
      console.log('voir l\'objet ', tr)
      this.$vs.notify({
        title: `Selected ${tr.description}`,
        text: `Email: ${tr.condition}`
      }) */
    },
    getOrderStatusColor1 (status) {
      if (status === 'red')   return '#FF0000'
      if (status === 'yellow') return '#E6FF00'
      if (status === 'white') return '#FFFFFF'
      if (status === 'green') return 'success'
    },
    submit_produit () {
      if (this.site !== '') {
        this.produit_submit = []
        this.conditionVrai = []
        this.conditionFaux = [] 
        this.conditionNa = []
        this.questions = []
        const produits_store = this.questions_list
        for (let i = 0; i < produits_store.length; i++) {
          this.conditionVrai.push(produits_store[i].conditionVrai)
          this.conditionFaux.push(produits_store[i].conditionFaux)
          this.conditionNa.push(produits_store[i].conditionNa)
          this.questions.push(produits_store[i].id)          
        }
        const obj = {
          id: this.id_produit++,
          site_obj : this.site,
          site : this.site.id,
          question:  this.questions,
          conditionVrai:this.conditionVrai,
          conditionFaux:this.conditionFaux,
          conditionNa:this.conditionNa
        }

        this.produit_submit = obj
     
     
        this.FIND_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
        if (this.itemIndexProduit === -1) {
          this.ADD_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
          this.$vs.notify({
            color:'success',
            icon:'done',
            text:this.$t('add_site_pro'),
            position:'top-right'
          })
          this.validateFormation_intialise()
        } else if (this.itemIndexProduit !== -1) {
          this.$vs.notify({
            color:'warning',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            time:15000,
            text:this.$t('add_site_pro_add'),
            position:'top-center'
          })
        }
      } else {
        this.$vs.notify({
          time: 4000,
          title: 'ATTENTION',
          text:this.$t('site_obligatoire'),
          color: 'warning',
          position: 'top-center'
        })
      }
    },
   
    updateProduit () {
      if (this.site !== '') {
        this.produit_submit = []
        this.conditionVrai = []
        this.conditionFaux = [] 
        this.conditionNa = []
        this.questions = []
        const produits_store = this.produits_computed
        for (let i = 0; i < produits_store.length; i++) {
          this.conditionVrai.push(produits_store[i].conditionVrai)
          this.conditionFaux.push(produits_store[i].conditionFaux)
          this.conditionNa.push(produits_store[i].conditionNa)
          this.questions.push(produits_store[i].id)          
        }
        const obj = {
          id: this.id_produit++,
          site_obj : this.site,
          site : this.site.id,
          question:  this.questions,
          conditionVrai:this.conditionVrai,
          conditionFaux:this.conditionFaux,
          conditionNa:this.conditionNa
        }

        this.produit_submit = obj
     
     
        this.FIND_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
        if (this.itemIndexProduit === -1) {
          this.ADD_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
          this.$vs.notify({
            color:'success',
            icon:'done',
            text:this.$t('site_obligatoire'),
            position:'top-right'
          })
          this.produits_computed = []
          this.site = ''
        } else if (this.itemIndexProduit !== -1) {
          this.$vs.notify({
            color:'warning',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            time:15000,
            text:this.$t('add_site_pro_add'),
            position:'top-center'
          })
        }
      } else {
        this.$vs.notify({
          time: 4000,
          title: 'ATTENTION',
          text:this.$t('site_obligatoire'),
          color: 'warning',
          position: 'top-center'
        })
      }
    },


    validateFormation_intialise () {
      window.riskZoneAssessments.getAllquestions()
      this.site = ''
      //this.conditions_tab = false
      //this.questions_tab = ''
      this.update_produitChek = false
    },
    resetData () {
      this.id = ''
      this.sites_tab = ''
      this.conditions_tab = false
      this.questions_tab = ''
      this.update_produitChek = false
    }, 
    deleteProduit (site) {
      this.REMOVE_ITEM_PRODUIT(this.produits_sites, site)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:this.$t('site_deleted_liste'),
        position:'top-right'
      })
    
    },
   
  
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:this.$t('quesion_update'),
        position:'top-right'
      })
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.site === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    UPDATE_ITEM_PRODUIT_2 (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id_obj === produit.id_obj)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.site === produit.site)
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.getAllquestions()
      this.Check_update = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
    ShowData (data) {
      this.intialise()
      this.Has_Med_Dos_Qty_update = true
      this.show_update = true
      this.id_produit =  ''
      this.site_all = []
      this.site_all_filter = []
      this.produits_computed = []
      this.produits_computed_new = []
      this.produits_computed_new_finale = []
      this.repProduit.length = 0
      this.repProduit_new.length = 0
      this.name = data.name
      //this.site = data.site.id
          
      for (let i = 0; i < data.questionHasFileHasSites.length; i++) {
        const obj = {
          id_obj:'',
          id: '',
          site:'',
          description: '',
          colorFaux: '',
          colorNa: '',
          colorVrai: '',
          conditionVrai: '',
          conditionFaux: '',
          conditionNa: ''
        }
        obj.id_obj = this.id_produit++
        obj.id = data.questionHasFileHasSites[i].question.id
        obj.colorFaux = data.questionHasFileHasSites[i].question.colorFaux
        obj.colorNa =   data.questionHasFileHasSites[i].question.colorNa
        obj.colorVrai = data.questionHasFileHasSites[i].question.colorVrai
        obj.description = data.questionHasFileHasSites[i].question.description
        obj.conditionVrai = data.questionHasFileHasSites[i].conditionVrai
        obj.conditionFaux = data.questionHasFileHasSites[i].conditionFaux
        obj.conditionNa =   data.questionHasFileHasSites[i].conditionNa
        obj.site =   data.questionHasFileHasSites[i].site.id
        this.site_all.push(data.questionHasFileHasSites[i].site.id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed_new, this.repProduit[j])
      }
      this.site_all_filter = [...new Set(this.site_all)]
      for (let y = 0; y < this.site_all_filter.length; y++) {
        const obj_up = {
          site : '',
          question_finale:[]
        } 
        for (let z = 0; z <  this.produits_computed_new.length; z++) {
          if (this.site_all_filter[y] === this.produits_computed_new[z].site) {
            obj_up.site = this.site_all_filter[y]
            obj_up.question_finale.push(this.produits_computed_new[z])
          }
        }
        this.produits_computed_new_finale.push(obj_up)
      }
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id_up = data.id
      this.Has_Med_Dos_Qty_update = true
      this.show_update = true
      this.id_produit =  ''
      this.site_all = []
      this.site_all_filter = []
      this.produits_computed = []
      this.produits_computed_new = []
      this.produits_computed_new_finale = []
      this.repProduit.length = 0
      this.repProduit_new.length = 0
      this.name = data.name
      //this.site = data.site.id
          
      for (let i = 0; i < data.questionHasFileHasSites.length; i++) {
        const obj = {
          id_obj:'',
          id: '',
          site:'',
          description: '',
          colorFaux: '',
          colorNa: '',
          colorVrai: '',
          conditionVrai: '',
          conditionFaux: '',
          conditionNa: ''
        }
        obj.id_obj = this.id_produit++
        obj.id = data.questionHasFileHasSites[i].question.id
        obj.colorFaux = data.questionHasFileHasSites[i].question.colorFaux
        obj.colorNa =   data.questionHasFileHasSites[i].question.colorNa
        obj.colorVrai = data.questionHasFileHasSites[i].question.colorVrai
        obj.description = data.questionHasFileHasSites[i].question.description
        obj.conditionVrai = data.questionHasFileHasSites[i].conditionVrai
        obj.conditionFaux = data.questionHasFileHasSites[i].conditionFaux
        obj.conditionNa =   data.questionHasFileHasSites[i].conditionNa
        obj.site =   data.questionHasFileHasSites[i].site.id
        this.site_all.push(data.questionHasFileHasSites[i].site.id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed_new, this.repProduit[j])
      }
      this.site_all_filter = [...new Set(this.site_all)]
      for (let y = 0; y < this.site_all_filter.length; y++) {
        const obj_up = {
          site : '',
          question_finale:[]
        } 
        for (let z = 0; z <  this.produits_computed_new.length; z++) {
          if (this.site_all_filter[y] === this.produits_computed_new[z].site) {
            obj_up.site = this.site_all_filter[y]
            obj_up.question_finale.push(this.produits_computed_new[z])
          }
        }
        this.produits_computed_new_finale.push(obj_up)
      }
      this.Check_update = true
      this.activePrompt2 = true
    },
   
    intialise () {
      this.id = ''
      this.id_up = ''
      this.name = ''
      this.site = ''
      this.sites = []
      this.conditionVrai = []
      this.conditionFaux = []
      this.conditionNa = []
      this.questions = []
      this.questions_list = []
      this.produits_computed = []
      this.validateFormation_intialise()
      this.show_update = false
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
      
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('suppression_valid_zone_exclusion'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`riskZoneAssessments/${id}/`)
            .then(response => {
              this.getAllriskZoneAssessments()
              window.getPrendTaCom.success(this.$t('suppression_valid_zone_exclusion'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('suppression_error_zone_exclusion') })
            })
        }
      })
  
    },
    submitData () {
      const finalObject = []
      const produits_final = []
      if (this.sites_list.length === this.produits_sites.length) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$vs.loading()
            if (this.produits_sites.length !== 0) {
              const produits_store = this.produits_sites

              for (let i = 0; i < produits_store.length; i++) {
                const obj = {
                  site : '',
                  question : '',
                  conditionVrais:'',
                  conditionFaux:'',
                  conditionNa:''
                }
                obj.site = produits_store[i].site
                obj.question = produits_store[i].question
                obj.conditionVrais = produits_store[i].conditionVrai
                obj.conditionFaux   = produits_store[i].conditionFaux
                obj.conditionNa = produits_store[i].conditionNa
                finalObject.push(obj)  
              }
              for (let j = 0; j <  finalObject.length; j++) {
                this.ADD_ITEM_PRODUIT(produits_final, finalObject[j])
              }

              this.input = {
                name : this.name,
                mission: this.mission_id,
                questionHasFileHasSites:produits_final
              }
            
              let url = 'riskZoneAssessments/'
              let methods = 'post'
              const message = {
                error: this.$t('save_error'),
                success: this.$t('riskZoneAssessment_save')
              }
              if (this.id_up) {
                url += `${this.id_up}/`
                methods = 'put'
                message.success = this.$t('riskZoneAssessment_update')
  
              }
  
              this.$http[methods](url, this.input)
                .then((response) => {
                  //window.riskZoneAssessments.getAllriskZoneAssessments()
                  window.getPrendTaCom.success(message.success, response)
                  this.intialise()
                  this.validateFormation_intialise()
                  this.missionStep()

                })
                .catch((error) => {
                  const clefs = Object.keys(error.response.data)
                  for (let i = 0; i < clefs.length; i++) {
                    const item = clefs[i]
                    if (item === 'name') {
                      this.$vs.notify({
                        time: 4000,
                        title: 'ATTENTION',
                        text: this.$t('name'),
                        color: 'warning',
                        position: 'top-center'
                      })
                    } else if (item === 'site') {
                      this.$vs.notify({
                        time: 4000,
                        title: 'ATTENTION',
                        text: this.$t('site'),
                        color: 'warning',
                        position: 'top-center'
                      })
                    } else if (item === 'sites') {
                      this.$vs.notify({
                        time: 4000,
                        title: 'ATTENTION',
                        text: this.$t('sites'),
                        color: 'warning',
                        position: 'top-center'
                      })
                    } else if (item === 'conditions') {
                      this.$vs.notify({
                        time: 4000,
                        title: 'ATTENTION',
                        text: this.$t('conditions'),
                        color: 'warning',
                        position: 'top-center'
                      })
                    } else if (item === 'questions') {
                      this.$vs.notify({
                        time: 4000,
                        title: 'ATTENTION',
                        text: this.$t('questions'),
                        color: 'warning',
                        position: 'top-center'
                      })
                    }
                  }
                  this.$vs.notify({
                    time: 8000,
                    title: this.$t('save_error_team'),
                    text: error.response.data.error,
                    color: 'danger',
                    position: 'top-right'
                  })
                  this.$vs.loading.close()
                })
            } else {
              this.$vs.notify({
                time: 4000,
                title: 'ATTENTION',
                text: this.$t('list_site'),
                color: 'warning',
                position: 'top-center'
              })

              this.$vs.loading.close()
            } 
          } else {
            this.$vs.notify({
              time: 4000,
              title: 'ATTENTION',
              text: this.$t('libelle_error'),
              color: 'warning',
              position: 'top-center'
            })
          }
        })
      } else {
        this.$vs.notify({
          time: 4000,
          title: 'ATTENTION',
          text: this.$t('site_insuf'),
          color: 'warning',
          position: 'top-center'
        })
      } 
    },
    getAllsites () {
      this.$vs.loading()
      const mission = this.mission_id
      this.$http.post('missionSites/', {mission})
        .then((response) => {
          this.sites_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllquestions () {
      this.$vs.loading()
      this.question_list = []
      this.$http.get('questions/')
        .then((response) => {
          this.questions_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllriskZoneAssessments () {
      this.$vs.loading()
      this.$http.get('riskZoneAssessments/')
        .then((response) => {
          this.riskZoneAssessments = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
 
  },
  created () {
    window.riskZoneAssessments = this
    this.getAllquestions()
    //this.getAllriskZoneAssessments()
  },
  mounted () {
    this.isMounted = true
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_journey
    this.mission_data_up = window.Planifications.planification_mission_data
    this.mission_id = this.mission_data_up.id
    this.mission_name = this.mission_data_up.poNumber
    if (this.mission_data === 1) {
      this.addNewData()
    } 
    if (this.mission_data === 2) {
      this.ShowData(this.mission_data_up.missionRiskZoneAssessment[0])
    } 
    if (this.mission_data === 3) {
      this.editData(this.mission_data_up.missionRiskZoneAssessment[0])
    }  
    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2

    }
    this.getAllsites()
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  